import Alpine from 'alpinejs';
// import intersect from '@alpinejs/intersect';
import intersectClass from 'alpinejs-intersect-class';
import Tooltip from "@ryangjchandler/alpine-tooltip";
import focus from '@alpinejs/focus';

Alpine.plugin(focus)
Alpine.plugin(Tooltip);
// Alpine.plugin(intersect);
Alpine.plugin(intersectClass);

window.Alpine = Alpine
Alpine.start();

import './smoothscroll';

document.addEventListener('DOMContentLoaded', function() {


});
